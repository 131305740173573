import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../environments/environments';
import { Router } from '@angular/router';

// username: admin.cashback	
// password: Admin123!
 
// username: agent.cashback
// password: Agent123!
 
// username: agentN2.cashback
// password: AgentN2123!
 
// username: agentDHO.cashback
// password: AgentDHO123!
 
// username: observer.cashback
// password: Observer123!
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  token$: BehaviorSubject<any> = new BehaviorSubject<any>('');

  key = 'AIzaSyA83mW31XIY5ppSDB1iKJ_VK-MgrIjNedk'
  
  client_id: string = "client_public_cashback";
  grant_type: string = "password";
  // client_secret: string = environment.AUTH_SECRET;
  scope: string = "grupomadero_cashback_api offline_access";

  constructor(private http: HttpClient, private _router: Router) {}

  onRequestLogin(username: string, pass: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const httpOptions = {
      headers: headers,
    };

    const payload = new HttpParams()
      .set('username', username)
      .set('password', pass)
      .set('client_id', this.client_id)
      // .set('client_secret', this.client_secret)
      .set('grant_type', this.grant_type)
      .set('scope', this.scope);

    return this.http.post<any>(
      `${environment.AUTH_API}connect/token`,
      payload.toString(),
      httpOptions,
    );
  }

  onRequestLoginFirebase(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    
    return this.http.post<any>(`${environment.AUTH_URL_FIREBASE}/accounts:signInWithPassword?key=${this.key}`, body);
  }

  lostPassword(mail: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const httpOptions = {
      headers: headers,
      responseType: 'json' as 'json',
    };
    return this.http.get<any>(`${environment.AUTH_API}/lost-password/${mail}`, httpOptions);
  }

  logOut(): Observable<any> {
    return of(this._router.navigate(['/']));
  }
}
